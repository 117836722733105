import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCode, faNetworkWired, faDatabase, faLanguage, faTerminal, faBriefcase, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { faHtml5, faReact, faJs, faPython, faJava, faJira, faBitbucket} from '@fortawesome/free-brands-svg-icons';

function Resume() {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  const [activeSection, setActiveSection] = useState('experience');

  useEffect(() => {
    const handleScroll = (e) => {
      const sections = document.querySelectorAll('section[id]');
      const scrollPosition = window.scrollY + 200;
    
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');
        
        if (
          scrollPosition > sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
            setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuItemClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
      setActiveSection(sectionId)
    }
    
  };
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);

useEffect(() => {
        const handleResize = () => {
                setIsMobileView(window.innerWidth < 1200);
        };
                
        window.addEventListener('resize', handleResize);
                
        return () => {
                window.removeEventListener('resize', handleResize);
        };
}, []);

return (
        <div className="flex flex-col md:flex-row bg-gradient-to-br from-gray-900 to-gray-800">
                {/* Lateral Menu */}
                {!isMobileView && (
                        <div className='mt-10 md:mt-20 fixed w-full md:w-1/4 lg:w-1/5 z-10 bg-gray-900 md:bg-transparent md:left-0 md:top-1/4 md:h-screen text-white overflow-y-auto pb-5 md:pb-10'>
                                <aside className="h-auto">
                                        <div className="flex flex-row md:flex-col justify-around md:justify-center">
                                                <h4
                                                        className={`text-base md:text-xl mb-2 md:mb-6 text-center text-[#54D3D4] cursor-pointer ${
                                                                activeSection === 'experience' ? 'font-bold text-lg md:text-xl' : ''
                                                        }`}
                                                        onClick={() => handleMenuItemClick('experience')}
                                                > Work Experience
                                                </h4>
                                                
                                                <h4
                                                        className={`text-base md:text-xl mb-2 md:mb-6 text-center text-[#54D3D4] cursor-pointer ${
                                                                activeSection === 'education' ? 'font-bold text-lg md:text-xl' : ''
                                                        }`}
                                                        onClick={() => handleMenuItemClick('education')}
                                                > Education
                                                </h4>
                                                
                                                <h4
                                                        className={`text-base md:text-xl mb-2 md:mb-6 text-center text-[#54D3D4] cursor-pointer ${
                                                                activeSection === 'skills' ? 'font-bold text-lg md:text-xl' : ''
                                                        }`}
                                                        onClick={() => handleMenuItemClick('skills')}
                                                > Skills
                                                </h4>
                                                
                                                <h4
                                                        className={`text-base md:text-xl mb-2 md:mb-6 text-center text-[#54D3D4] cursor-pointer ${
                                                                activeSection === 'languages' ? 'font-bold text-lg md:text-xl' : ''
                                                        }`}
                                                        onClick={() => handleMenuItemClick('languages')}
                                                >
                                                        Languages
                                                </h4>
                                        </div>
                                </aside>
                        </div>
                )}

                <main className="mt-20 px-4 md:ml-16 lg:ml-32 w-full flex justify-center"> 
                        <div id="about" className="w-full max-w-4xl text-white grid grid-cols-1 gap-20 md:gap-60">
                                {/* Experience */}
                                <section id="experience" className="rounded-lg p-4 md:p-6 text-[#C4D1EC]">
                                        <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'experience' ? 'font-bold' : ''}`}>Work Experience <FontAwesomeIcon icon={faBriefcase} className='ml-3'/></h2>
                                        <div className="space-y-6">
                                                <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <a href="https://www.webcargo.co/" target='_blank' rel="noreferrer" className="linkcard block md:grid md:grid-cols-5 gap-2 md:gap-4 p-3 md:p-4">
                                                                <div className='col-span-1 mb-2 md:mb-0'>
                                                                        <h4 className='text-sm md:text-md text-center text-slate-500'>2022 — present</h4>
                                                                        <h4 className='text-xs md:text-sm text-center text-slate-300'>Barcelona, Spain</h4>
                                                                </div>
                                                                <div className='col-span-4'>
                                                                        <h3 className="text-base md:text-lg font-bold mb-2 text-white">Team Lead & Software Engineer - Webcargo <FontAwesomeIcon icon={faLink} className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                                                                        <p className="text-xs md:text-sm">As a Team Lead & Software Engineer at WebCargo, I lead the development of innovative features while ensuring the integrity and reliability of our application. Overseeing a team of developers, I am responsible for building and maintaining critical components essential to our platform's functionality.</p>
                                                                        <p className="text-xs md:text-sm mt-2">I collaborate closely with cross-functional teams to ensure seamless integration and advocate for best practices in software development.</p>
                                                                        <p className="text-xs md:text-sm font-medium mt-3">Key projects:</p>
                                                                        <ul className="text-xs md:text-sm mt-1 space-y-2">
                                                                                <li><span className="font-bold">Scheduling System:</span> Designed and implemented a scheduling system specifically aimed at optimizing repetitive tasks for our clients.</li>
                                                                                <li><span className="font-bold">Third-Party API Integration:</span> Directed the integration of our platform with a third-party service through a complex API.</li>
                                                                                <li><span className="font-bold">New Email System:</span> Spearheaded the creation of a new email system to improve internal and client communications.</li>
                                                                        </ul>
                                                                        <ul className='chiplist flex flex-wrap gap-2 mt-3'>
                                                                                <li><div className='chip text-xs'>PHP</div></li>
                                                                                <li><div className='chip text-xs'>React</div></li>
                                                                                <li><div className='chip text-xs'>MySQL</div></li>
                                                                                <li><div className='chip text-xs'>Jira</div></li>
                                                                                <li><div className='chip text-xs'>Confluence</div></li>
                                                                        </ul>
                                                                </div>
                                                        </a>
                                                </div>
                                        
                                                <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <a href="https://www.webcargo.co/" target='_blank' rel="noreferrer" className="linkcard block md:grid md:grid-cols-5 gap-2 md:gap-4 p-3 md:p-4">
                                                                <div className='col-span-1 mb-2 md:mb-0'>
                                                                        <h4 className='text-sm md:text-md text-center text-slate-500'>2019 — 2022</h4>
                                                                        <h4 className='text-xs md:text-sm text-center text-slate-300'>Barcelona, Spain</h4>
                                                                </div>
                                                                <div className='col-span-4'>
                                                                        <h3 className="text-base md:text-lg font-bold mb-2 text-white">Senior Software Engineer - Webcargo <FontAwesomeIcon icon={faLink} className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                                                                        <p className="text-xs md:text-sm">In my previous role at WebCargo as a Software Engineer, I was instrumental in developing new features while upholding the integrity of the application. I actively contributed to enhancing the platform's functionality, focusing on improving user experience and driving business success.</p>
                                                                        <p className="text-xs md:text-sm font-medium mt-3">Key projects:</p>
                                                                        <ul className="text-xs md:text-sm mt-1 space-y-2">
                                                                                <li><span className="font-bold">Payment System:</span> Implemented a comprehensive payment system with secure transaction processing and multiple payment gateway integrations.</li>
                                                                                <li><span className="font-bold">Search History:</span> Implemented a search history functionality to enhance user experience with personalized search results.</li>
                                                                                <li><span className="font-bold">Package System:</span> Introduced a new package management system designed for flexibility and scalability.</li>
                                                                        </ul>
                                                                        <ul className='chiplist flex flex-wrap gap-2 mt-3'>
                                                                                <li><div className='chip text-xs'>PHP</div></li>
                                                                                <li><div className='chip text-xs'>React</div></li>
                                                                                <li><div className='chip text-xs'>MySQL</div></li>
                                                                                <li><div className='chip text-xs'>CSS</div></li>
                                                                                <li><div className='chip text-xs'>APIs</div></li>
                                                                        </ul>
                                                                </div>
                                                        </a>
                                                </div>
                                        
                                                <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <a href="https://www.nextlane.com/es/" target='_blank' rel="noreferrer" className="linkcard block md:grid md:grid-cols-5 gap-2 md:gap-4 p-3 md:p-4">
                                                                <div className='col-span-1 mb-2 md:mb-0'>
                                                                        <h4 className='text-sm md:text-md text-center text-slate-500'>2018 — 2019</h4>
                                                                        <h4 className='text-xs md:text-sm text-center text-slate-300'>Madrid, Spain</h4>
                                                                </div>
                                                                <div className='col-span-4'>
                                                                        <h3 className="text-base md:text-lg font-bold mb-2 text-white">Junior Software Engineer - Imaweb/Nextlane <FontAwesomeIcon icon={faLink} className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                                                                        <p className="text-xs md:text-sm">As a Junior Software Engineer at Imaweb, I played a vital role in the development of new features while ensuring the integrity of our applications. Working closely with senior engineers, I contributed to implementing innovative functionalities aimed at enhancing user experience.</p>
                                                                        <ul className='chiplist flex flex-wrap gap-2 mt-3'>
                                                                                <li><div className='chip text-xs'>PHP</div></li>
                                                                                <li><div className='chip text-xs'>JQuery</div></li>
                                                                                <li><div className='chip text-xs'>CSS</div></li>
                                                                        </ul>
                                                                </div>
                                                        </a>
                                                </div>
                                        
                                                <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <a href="https://www.colanguage.com/" target='_blank' rel="noreferrer" className="linkcard block md:grid md:grid-cols-5 gap-2 md:gap-4 p-3 md:p-4">
                                                                <div className='col-span-1 mb-2 md:mb-0'>
                                                                        <h4 className='text-sm md:text-md text-center text-slate-500'>2018 <span className='text-xs'>(Apr-Aug)</span></h4>
                                                                        <h4 className='text-xs md:text-sm text-center text-slate-300'>Ghent, Belgium</h4>                        
                                                                </div>
                                                                <div className='col-span-4'>
                                                                        <h3 className="text-base md:text-lg font-bold mb-2 text-white">Intern Software Engineer - CoLanguage <FontAwesomeIcon icon={faLink} className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                                                                        <p className="text-xs md:text-sm">As an Intern Software Engineer at CoLanguage, I actively participated in developing new features while prioritizing the integrity of our application. Under the guidance of experienced engineers, I contributed to implementing innovative functionalities aimed at improving user experience.</p>
                                                                        <ul className='chiplist flex flex-wrap gap-2 mt-3'>
                                                                                <li><div className='chip text-xs'>Drupal</div></li>
                                                                                <li><div className='chip text-xs'>JavaScript</div></li>
                                                                        </ul>
                                                                </div>
                                                        </a>
                                                </div>
                                        </div>
                                </section>

                                {/* Education */}
                                <section id="education" className="rounded-lg p-4 md:p-6 text-[#C4D1EC]">
                                        <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'education' ? 'font-bold' : ''}`}>Education <FontAwesomeIcon icon={faUserGraduate} className='ml-3' /></h2>
                                        <div>
                                                <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <a href="https://www.iesgrancapitan.org/" target='_blank' rel="noreferrer" className="linkcard block md:grid md:grid-cols-5 gap-2">
                                                                <div className='col-span-1'>
                                                                        <h4 className='text-md text-center text-slate-500'>2016 — 2018</h4>
                                                                </div>
                                                                <div className='col-span-3'>
                                                                        <h3 className="text-lg font-bold mb-2 text-white">Web Application Development <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                                                                        <p className="text-sm">I.E.S Gran Capitan — Córdoba</p>
                                                                </div>
                                                                <div className='col-span-1 flex justify-center items-center'>
                                                                        <FontAwesomeIcon icon={faTerminal} size="2xl"/>
                                                                </div>
                                                        </a>
                                                </div>
                                                <div className="mb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <a href="https://www.iesmedinaazahara.es/" target='_blank' rel="noreferrer" className="linkcard block md:grid md:grid-cols-5 gap-2">
                                                                <div className='col-span-1'>
                                                                        <h4 className='text-md text-center text-slate-500'>2014 — 2016</h4>
                                                                </div>
                                                                <div className='col-span-3'>
                                                                        <h3 className="text-lg font-bold mb-2 text-white">Microcomputer Systems and Networks <FontAwesomeIcon icon={faLink}  className={`ml-1 ${isHovered ? 'animate-pulse' : ''}`} size='xs'/></h3>
                                                                        <p className="text-sm">I.E.S Medina Azahara  — Córdoba</p>
                                                                </div>
                                                                <div className='col-span-1 flex justify-center items-center'>
                                                                        <FontAwesomeIcon icon={faNetworkWired} size="2xl" />
                                                                </div>
                                                        </a>
                                                </div>
                                        </div>
                                </section>
                                
                                {/* Skills */}
                                <section id="skills" className="rounded-lg p-4 md:p-6 text-[#C4D1EC]">
                                        <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'skills' ? 'font-bold' : ''}`}>Skills <FontAwesomeIcon icon={faCode} className='ml-3' /></h2>
                                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6">
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faCode} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">PHP</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faHtml5} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">HTML/CSS</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faReact} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">React</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faJs} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">JavaScript</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faPython} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">Python</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faDatabase} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">MySQL</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faJava} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">Java</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faJira} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">Jira</span>
                                                </div>
                                                <div className="skillscard">
                                                        <FontAwesomeIcon icon={faBitbucket} size="2x" className="md:text-4xl" />
                                                        <span className="text-sm md:text-md">Bitbucket</span>
                                                </div>
                                        </div>
                                </section>

                                {/* Languages */}
                                <section id="languages" className="rounded-lg p-4 md:p-6 text-[#C4D1EC]">
                                        <h2 className={`text-xl mb-6 text-center text-[#54D3D4] ${activeSection === 'languages' ? 'font-bold' : ''}`}>Languages <FontAwesomeIcon icon={faLanguage} className='ml-3' /></h2>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-5">
                                                <div className="skillscard flex flex-col justify-center items-center">
                                                        <span className="text-md">Spanish</span>
                                                        <div className="w-20 h-1 flex mt-1">
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                        </div>
                                                </div>
                                                <div className="skillscard flex flex-col justify-center items-center">
                                                        <span className="text-md">English</span>
                                                        <div className="w-20 h-1 flex mt-1">
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                                                        </div>    
                                                </div>
                                                <div className="skillscard flex flex-col justify-center items-center">
                                                        <span className="text-md">Catalan</span>
                                                        <div className="w-20 h-1 flex mt-1">
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                                                        </div>
                                                </div>
                                                <div className="skillscard flex flex-col justify-center items-center">
                                                        <span className="text-md">French</span>
                                                        <div className="w-20 h-1 flex mt-1">
                                                                <div className="flex-1 bg-green-500 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                                                                <div className="flex-1 bg-gray-300 rounded-full mx-1"></div>
                                                        </div>
                                                </div>
                                        </div>
                                </section>

                                <section id="hidden" className='h-20 md:h-60'></section>
                        </div>
                </main>
        </div>
);
}

export default Resume;
