import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer className="flex w-full flex-col border-t border-blue-gray-50 py-6 text-white  bg-gradient-to-br from-gray-900 to-gray-800">
      <div className="container mx-auto px-4 md:px-10">
        <div className="flex flex-col md:flex-row md:justify-between items-center gap-4 md:gap-0">
          {/* Attribution */}
          <div className="text-sm text-center md:text-left">
            <p>Designed and Developed by Jose Lucena</p>
          </div>

          {/* Copyright */}
          <div className="text-center order-3 md:order-2 mt-4 md:mt-0">
            <p>Copyright &copy; {year} <span className="text-bold text-xs">LM</span></p>
          </div>

          {/* Social Media Links */}
          <div className="text-center md:text-right order-2 md:order-3">
            <a href="https://www.linkedin.com/in/joselucena/" target='_blank' rel="noopener noreferrer" className="text-white hover:text-gray-400 mx-2"><FontAwesomeIcon icon={faLinkedin} size="lg" /></a>
            <a href="https://www.instagram.com/joselucena_rt/" target='_blank' rel="noopener noreferrer" className="text-white hover:text-gray-400 mx-2"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
