import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 px-4 py-20 md:py-0">
      {/* Presentation Section */}
      <div className="w-full md:w-2/3 lg:w-1/2 text-white transform transition-all duration-500 hover:scale-105">
        <div className="max-w-md mx-auto backdrop-filter backdrop-blur-sm bg-opacity-20 bg-gray-800 p-8 rounded-xl shadow-2xl border border-gray-700">
          <h3 className="text-xl md:text-2xl mb-3 text-[#54D3D4] font-medium tracking-wide">Welcome, my name is</h3>
          <h1 className='text-4xl md:text-6xl font-bold text-[#C5D1ED] tracking-tight leading-none mb-4'>JOSE LUCENA</h1>
          <p className="text-base md:text-lg text-[#C4D1EC] mt-3 leading-relaxed opacity-90">I'm a Software Engineer specialized in Web Development.</p>
          <div className="mt-8">
            <button 
              onClick={() => navigate('/contact')} 
              className="bg-[#54D3D4] hover:bg-[#3CBFC0] text-gray-900 font-medium py-2 px-6 rounded-lg transition-all duration-300 mr-4">
              Contact Me
            </button>
            <button className="border border-[#54D3D4] text-[#54D3D4] hover:bg-[#54D3D4] hover:bg-opacity-10 font-medium py-2 px-6 rounded-lg transition-all duration-300" hidden="true">
              View Projects
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
