import React from 'react';

function About() {
  return (
    <section id="about" className="flex flex-col md:flex-row justify-center items-center min-h-screen py-12 px-4  bg-gradient-to-br from-gray-900 to-gray-800">
      {/* Presentation Section */}
      <div className="w-full md:w-1/2 py-8 md:py-16 px-4 md:px-8 text-white">
        <div className="max-w-md mx-auto">
          <h3 className="text-2xl md:text-3xl mb-2 text-[#54D3D4]">About Me</h3>
          <p className="text-base md:text-lg text-[#C4D1EC] mt-3">
            I'm a <b>Senior Software Engineer</b> with a deep specialization in web application development, particularly in PHP. My career has been defined by my ability to design and develop complex systems that are both efficient and scalable.
          </p>
          <br/>
          <p className="text-base md:text-lg text-[#C4D1EC] mt-3">
            In addition to my technical skills, I have significant experience as a <b>Team Lead</b>. I guide and support development teams, ensuring projects are executed efficiently while fostering a collaborative environment.
          </p>      
        </div>
      </div>
    </section>
  );
}

export default About;
