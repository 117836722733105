import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons';

function Menu() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav id='main-menu' className="top-0 left-0 w-full p-4 bg-gray-900 text-white">
      <div className="container mx-auto py-4 flex flex-wrap items-center justify-between">
        <div className="flex items-center">
          <span className="text-xl font-bold text-blue-400">My Portfolio</span>
        </div>
        
        {/* Hamburger menu button for mobile */}
        <button 
          onClick={toggleMenu}
          className="md:hidden inline-flex items-center p-2 ml-3 text-white rounded-lg hover:bg-gray-800 focus:outline-none"
        >
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
          </svg>
        </button>
        
        {/* Navigation links */}
        <div className={`${menuOpen ? 'block' : 'hidden'} w-full md:flex md:w-auto md:items-center`}>
          <ul className="flex flex-col mt-4 md:flex-row md:mt-0">
            <li className="mb-2 md:mb-0 md:mr-9">
              <NavLink 
                to="/" 
                onClick={() => setMenuOpen(false)}
                className={`block text-gray-300 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2 transition-colors duration-200 ${location.pathname === '/' ? 'bg-blue-600 text-white' : ''}`}
              >
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                Home
              </NavLink>
            </li>
            <li className="mb-2 md:mb-0 md:mr-9">
              <NavLink 
                to="/about" 
                onClick={() => setMenuOpen(false)}
                className={`block text-gray-300 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2 transition-colors duration-200 ${location.pathname === '/about' ? 'bg-blue-600 text-white' : ''}`}
              >
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                About
              </NavLink>
            </li>
            <li className="mb-2 md:mb-0 md:mr-9">
              <NavLink 
                to="/resume" 
                onClick={() => setMenuOpen(false)}
                className={`block text-gray-300 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2 transition-colors duration-200 ${location.pathname === '/resume' ? 'bg-blue-600 text-white' : ''}`}
              >
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                Resume
              </NavLink>
            </li>
            <li className="mb-2 md:mb-0 md:mr-9">
              <NavLink 
                to="/contact" 
                onClick={() => setMenuOpen(false)}
                className={`block text-gray-300 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2 transition-colors duration-200 ${location.pathname === '/contact' ? 'bg-blue-600 text-white' : ''}`}
              >
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
